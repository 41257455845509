import axios from "axios";

const client = axios.create({
  baseURL: "https://api.omnitracker.prod.labs.lixil.com/api",
  headers: {
    "Content-Type": "application/json",
    "X-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

export default client;
